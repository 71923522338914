body {
  background-color: #5c5470;
}

* {
  font-family: arial;
}

div.post {
  border-style: solid;
  border: 2px solid #263849;
  border-radius: 5px;
  margin: 10px 100px;
  padding: 0px 20px;
  background-color: #352f44;
}

div#new_post {
  border-style: solid;
  border: 2px solid #263849;
  border-radius: 5px;
  margin: 10px 100px;
  padding: 0px 20px 33px 20px;
  background-color: #352f44;
}

h1 {
  color: #FFFFFF;
  padding-left: 10px;
}

h4, h2 {
  display: inline-block;
  color: #FFFFFF;
}
p {
  color: #FFFFFF;
}

h4 {
  float: right;
}

input#title {
  height: 30px;
  width: 35%;
  border-radius: 5px;
}

input#username {
  height: 30px;
  width: 25%;
  float: right;
  border-radius: 5px;
}

textarea#content {
  min-width: 100%;
  max-width: 100%;
  min-height: 160px;
  max-height: 1000px;
  border-radius: 5px;
}

.missingInput {
  border: 2px solid red;
}

input#submit {
  float: right;
  margin-top: 5px;
}
